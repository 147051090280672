

















































































































import ParameterType from '@/models/ParameterType';
import Vue from 'vue';
import { Component, Prop, Emit } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class ParameterEditor extends Vue {
  public hasChanged = false;
  public ParameterType = ParameterType;

  @Prop({ required: true })
  public parameterDefinitionId!: string;

  @Prop({ required: true })
  public value!: string;

  @Prop({ required: true })
  public numberOfOptions!: number;

  @Prop({ required: true })
  public parameterType!: ParameterType;

  @Emit('change')
  // eslint-disable-next-line
  public onParameterChanged(parameterDefinitionId: string, newValue: string): void {
    this.hasChanged = true;
  }
}
